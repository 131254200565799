<script context="module">
    let modals = [];
</script>

<script>
    import { browser } from '$app/environment';
    import { createEventDispatcher, onMount, tick } from 'svelte';
    import { fade, fly } from 'svelte/transition';
    import Icon from './Icon.svelte';

    export let xs = false;
    export let sm = false;
    // export let lg = false;
    // export let xl = false;

    export let collapsible = true;

    let modalContent;
    let layer = modals.length;

    const handle_escape = (e) => {
        if (e.key === 'Escape') {
            handleClose(e);
        }
    };

    // @ts-ignore
    onMount(async () => {
        if (!browser) {
            return;
        }
        await tick();
        let modalTarget = document.querySelector('#modal-target');

        modalContent.classList.add(`layer-${layer}`);
        modals = [...modals, modalContent];
        modalTarget.appendChild(modalContent);
        document.body.classList.add('has-modal');

        document.addEventListener('keydown', handle_escape);

        return async () => {
            if (!browser) {
                return;
            }

            modals = modals.filter((m) => m !== modalContent);
            await tick();

            if (modalTarget && modalTarget.querySelector(`.layer-${layer}`)) {
                //this covers case when parent of modal component is destroyed before the outro animation finishes
                modalTarget.removeChild(
                    modalTarget.querySelector(`.layer-${layer}`)
                );
            }

            if (!modals.length) {
                document.body.classList.remove('has-modal');
            }

            document.removeEventListener('keydown', handle_escape);
        };
    });

    let dispatch = createEventDispatcher();

    function handleClose(e) {
        if (!collapsible) {
            return;
        }

        if (layer >= modals.length - 1) {
            modals = modals.slice(0, -1);
            dispatch('close');
        }
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
    class="bg-black/70 w-full h-[100dvh] absolute"
    bind:this={modalContent}
    transition:fly|local={{ y: 100, duration: 200 }}
    aria-modal="true"
>
    {#if collapsible}
        <div
            in:fade={{ delay: 200 }}
            out:fly={{ y: -200, duration: 200 }}
            on:click={handleClose}
            class="close p-4 aspect-square text-center cursor-pointer rounded-full rounded-tr-none bg-black hover:bg-white/10 flex items-center justify-center"
        >
            <Icon times class="drop-shadow" on:click={handleClose} />
        </div>
    {/if}

    <div
        class="
			modal
			absolute bottom-0 left-1/2 -translate-x-1/2
			w-full
			flex flex-col
			rounded-t-3xl

            bg-surface-invert-main
            text-symbols-invert-default

			shadow-xl
			pb-safe

			{xs ? 'max-w-sm' : sm ? 'max-w-md' : 'max-w-xl'}

			sm:bottom-auto
			sm:top-1/2 sm:-translate-y-1/2
			sm:rounded-b-3xl
		"
    >
        {#if $$slots.header}
            <div class="p-4">
                <slot name="header" />
            </div>
        {/if}

        <div class="p-4 flex-1 overflow-y-auto">
            <slot />
        </div>

        {#if $$slots.footer}
            <div class="p-4 border-t border-border-invert-muted">
                <slot name="footer" />
            </div>
        {/if}
    </div>
</div>

<style lang="postcss">
    .modal {
        max-height: calc(100% - calc(env(safe-area-inset-top) + 4rem));
        min-height: 30%;
    }

    .modal.max-w-sm,
    .modal.max-w-md {
        min-height: 0;
    }

    /* :global(.has-tabs) .modal {
		@apply lg:ml-10 xl:ml-32;
	} */

    .close {
        @apply absolute right-2;
        top: max(env(safe-area-inset-top), 0);
    }
</style>
