<script>
    import { page } from '$app/stores';
    import Button from './Button.svelte';
    import { logEvent } from './utils/logs';
    import * as Sentry from '@sentry/sveltekit';
    import { img_cdn } from '$lib/utils/image';

    let { supabase } = $page.data;
    $: ({ supabase } = $page.data);

    let loading = false;

    async function handle_google_sign_in() {
        loading = true;

        

        let redirect_path = $page.url.searchParams.get('redirectTo') || $page.url.pathname || '/';

        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: `${window.location.origin}/auth/callback?next=${redirect_path}`,
            }
        });

        if (error) {
            console.error('Google sign in failed:', error);
            logEvent('auth:error:signInWithGoogle', { message: error.message });
            Sentry.captureException(new Error('Google sign in failed', { cause: error }));
        }

        loading = false;
    }
</script>

<Button 
    outline 
    invert 
    xl 
    {loading} 
    loading_spinner 
    on:click={handle_google_sign_in}
>
    <img src={img_cdn('/icon-google-color.svg')} alt="Google logo" class="w-6 h-6 mr-2 inline-block align-text-bottom" />
    Sign in with Google
</Button>