<script>
    import { page } from '$app/stores';
    import Button from './Button.svelte';
    import Modal from './Modal.svelte';
    import H2 from './H2.svelte';
    import { createEventDispatcher, tick, onMount } from 'svelte';
    import Icon from './Icon.svelte';
    import Prose from './Prose.svelte';
    import AuthEmail from './AuthEmail.svelte';
    import AuthGoogle from './AuthGoogle.svelte';

    let { supabase, session } = $page.data;
    $: ({ supabase, session } = $page.data);

    export let hidden = false;
    export let collapsible = true;
    export let button_props = {};

    let show_modal = false;
    let auth_method = null;

    const dispatch = createEventDispatcher();

    export function show() {
        handle_modal_show();
    }

    export function hide() {
        handle_modal_close();
    }

    function handle_modal_close() {
        if (!collapsible) return;
        show_modal = false;
        auth_method = null;
    }

    async function handle_modal_show() {
        show_modal = true;
        auth_method = null;
        await tick();
    }

    async function handle_sign_out() {
        await supabase.auth.signOut();
        location.reload();
    }

    function set_auth_method(method) {
        auth_method = method;
    }

    let ctm_email = '';
    /** @type {boolean | null}*/
    let is_gmail_email = null;

    onMount(() => {
        ctm_email = localStorage.getItem('ctm_email') || '';
        is_gmail_email = ctm_email ? ctm_email.toLowerCase().endsWith('@gmail.com') : null;
    });
</script>

{#if !hidden}
    {#if session}
        <Button
            outline
            on:click={handle_sign_out}
            class="whitespace-nowrap"
            {...{ ...{ outline: true }, ...button_props }}
        >
            <Icon log_out class="max-sm:hidden" /> Log Out
        </Button>
    {:else}
        <Button
            class="whitespace-nowrap"
            {...{
                ...{ primary: true, outline: true },
                ...button_props
            }}
            on:click={handle_modal_show}
        >
            <slot name="button">
                <Icon log_in class="max-sm:hidden" /> Log In
            </slot>
            <svelte:fragment slot="small"><slot name="button_small" /></svelte:fragment>
        </Button>
    {/if}
{/if}

{#if show_modal}
    <Modal on:close={handle_modal_close} sm {collapsible}>
        <H2 invert class="max-w-xs mx-auto mt-8">Please log in with the email used for purchase</H2>
        
        {#if !auth_method}
            <div class="flex flex-col gap-4 max-w-xs mx-auto mt-8">

                    <AuthGoogle/>
                    {#if is_gmail_email === true}
                        <div class="mb-2 text-center">
                            <Icon arrow_up class="mr-1" /> Last used
                        </div>
                    {/if}
                
                    <Button 
                        outline 
                        invert 
                        xl
                        on:click={() => set_auth_method('email')}
                    >
                        <Icon mail class="mr-2 !align-text-bottom" /> Sign in with Email
                    </Button>
                    {#if is_gmail_email === false}
                        <div class="text-center">
                            <Icon arrow_up class="mr-1" /> Last used
                        </div>
                    {/if}
            </div>
        {:else if auth_method === 'email'}
            <AuthEmail on:loged={(event) => dispatch('loged', event.detail)} />
            <Button sm class="mt-4" on:click={() => set_auth_method(null)}>Back to options</Button>
        {/if}

        <div class="border-t border-border-invert-muted mt-8 pt-4">
            <Prose invert xs class="max-w-xs mx-auto">
                <p>
                    By logging in, you agree to the <a href="/terms" target="_blank" class="link">Terms of Service</a>
                    and
                    <a href="/privacy" target="_blank" class="link">Privacy Policy</a>.
                </p>
                <p>
                    If you are 13 or younger, you must have the permission of a parent or guardian.
                </p>
            </Prose>
        </div>
    </Modal>
{/if}
